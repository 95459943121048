import { Tooltip } from '../../scales';

type Props = {
  className: string;
  costExact?: string;
  costPercentage?: string;
  costRounded?: string;
  description: string;
  icon?: JSX.Element;
  isBold?: boolean;
  label: string;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showTooltips?: boolean;
  'data-cy': string;
};

export default function ProjectCostRow(props: Props) {
  const content = (
    <div className="flex w-full">
      {props.icon && (
        <div className="flex w-10 items-center justify-center text-right">{props.icon}</div>
      )}
      <div className="line-clamp-1 flex w-full items-center">{props.label}</div>
      {props.costPercentage && (
        <div className="ml-auto w-10 text-right">{props.costPercentage}</div>
      )}
      <div className="ml-auto w-50 text-right" data-cy={props['data-cy']}>
        {props.costRounded}
      </div>
    </div>
  );

  return (
    <Tooltip
      content={
        <div className="flex flex-col gap-0.5">
          <div className="type-label">{props.description}</div>
          <div className="type-table-number">{props.costExact}</div>
        </div>
      }
      isDisabled={!props.costExact || !props.showTooltips}
      offset={20}
      placement="right"
    >
      <div
        className={`${props.className} flex w-full items-center justify-between gap-1 type-table-text`}
      >
        {props.isBold ? <b className="w-full">{content}</b> : content}
      </div>
    </Tooltip>
  );
}
