import { ComponentProps } from 'react';

import { IconButton, ScrollContainer, Tooltip } from '../../scales';
import { Link } from '../types';

import CompanySection from './CompanySection';
import useLinks from './hooks/useLinks';
import NavItem from './NavItem';
import SupportMenuButton from './SupportMenuButton';
import UserMenuButton from './UserMenuButton';
import { LastViewedEntry } from './hooks/useLastViewedEntries';
import { CollapseSidebarIcon } from '../icons';
import SearchButton from '../SearchButton';
import JoinLogoNavLink from './JoinLogoNavLink';
import NotificationsButton from './NotificationsButton';
import ProjectDetails from './Details/ProjectDetails';
import ProgramDetails from './Details/ProgramDetails';

type Props = {
  company: ComponentProps<typeof CompanySection>['company'];
  lastViewedEntries: LastViewedEntry[];
  onCollapse: () => void;
  user: ComponentProps<typeof UserMenuButton>['user'];
};

export default function SidebarExpanded(props: Props) {
  const lastViewedEntry = props.lastViewedEntries.length ? props.lastViewedEntries[0] : undefined;

  const { companyLinks, programLinks, projectLinks } = useLinks(lastViewedEntry);

  // notifications currently only work for projectIDs
  const lastViewedProjectID = props.lastViewedEntries.find((e) => e.type === 'project')?.id;

  return (
    <div className="flex h-full w-[250px] flex-col">
      <header className="flex items-center p-4">
        <JoinLogoNavLink size="lg" />
        <SearchButton />
        <NotificationsButton projectID={lastViewedProjectID} />
        <Tooltip content="Collapse sidebar">
          <IconButton
            aria-label="collapse sidebar"
            icon={<CollapseSidebarIcon />}
            onClick={props.onCollapse}
            type="secondary"
          />
        </Tooltip>
      </header>
      <ScrollContainer direction="vertical">
        <div className="flex flex-col gap-4">
          {lastViewedEntry && (
            <>
              {lastViewedEntry.type === 'project' ? (
                <ProjectSection lastViewedProjectID={lastViewedEntry.id} links={projectLinks} />
              ) : (
                <ProgramSection links={programLinks} programID={lastViewedEntry.id} />
              )}
            </>
          )}
          <CompanySection
            company={props.company}
            isExpanded
            lastViewedEntries={props.lastViewedEntries}
            links={companyLinks}
          />
        </div>
      </ScrollContainer>
      <footer className="mt-auto flex items-center justify-between border-t border-border-separator p-2">
        <UserMenuButton expanded user={props.user} />
        <SupportMenuButton position={{ left: 250 }} />
        {/* 250px is width of sidebar */}
      </footer>
    </div>
  );
}

type ProjectSectionProps = {
  lastViewedProjectID: UUID;
  links: Link[];
};
function ProjectSection(props: ProjectSectionProps) {
  return (
    <div className="flex flex-col gap-2" data-cy="project">
      <ProjectDetails projectID={props.lastViewedProjectID} />
      <div className="flex flex-col items-center" data-cy="project--links">
        {props.links.map((link) => (
          <NavItem key={link.name} icon={link.icon} label={link.name} path={link.path} />
        ))}
      </div>
    </div>
  );
}

type ProgramSectionProps = {
  programID: UUID;
  links: Link[];
};
function ProgramSection(props: ProgramSectionProps) {
  return (
    <div className="flex flex-col gap-2" data-cy="project">
      <ProgramDetails programID={props.programID} />
      <div className="flex flex-col items-center" data-cy="project--links">
        {props.links.map((link) => (
          <NavItem key={link.name} icon={link.icon} label={link.name} path={link.path} />
        ))}
      </div>
    </div>
  );
}
