import { Link } from 'react-router-dom';

import { JoinProjectRoutes, TermKey } from '../../../../api/gqlEnums';
import useProjectTermStore from '../../../../hooks/useProjectTermStore';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { getCostValue } from '../../../CostReport/CostReportUtils';
import useSummaryCostReport from '../hooks/useSummaryCostReport';
import CostRow from './CostRow';

export default function CostsSummary(props: {
  activeMilestoneID?: UUID;
  projectID?: UUID;
  quantity?: Quantity;
}) {
  const terms = useProjectTermStore();
  const report = useSummaryCostReport(props.projectID, props.activeMilestoneID, props.quantity);
  const hasNonzeroTarget = Boolean(getCostValue(report.target));
  const hasExactCost = Boolean(props.quantity); // If we are showing quantity, display exact values

  const path =
    props.projectID && props.activeMilestoneID
      ? generateSharedPath(JoinProjectRoutes.MSR, {
          projectId: props.projectID,
          milestoneId: props.activeMilestoneID,
        })
      : '';

  return (
    <Link
      className="flex flex-col gap-1 outline-none focus-visible:bg-selection-hover"
      data-cy="sidebar-cost-summary"
      to={path}
    >
      <CostRow
        className="text-type-primary"
        cost={report.estimate}
        data-cy="cost-summary-estimate"
        description="Base estimate for the active milestone"
        hasExactCost={hasExactCost}
        label={terms.titleCase(TermKey.ESTIMATE)}
      />
      <CostRow
        className="text-item-status-accepted"
        cost={report.accepted}
        data-cy="cost-summary-accepted-changes"
        description="Value of all accepted items"
        hasExactCost={hasExactCost}
        label="Accepted Changes"
      />
      <div className="col-span-2 h-px w-full bg-border-separator" />
      <CostRow
        className="text-type-primary"
        cost={report.running}
        data-cy="cost-summary-running-total"
        description="Revised estimate including accepted items"
        hasExactCost={hasExactCost}
        label={terms.titleCase(TermKey.RUNNING_TOTAL)}
      />
      {hasNonzeroTarget ? ( // only show the gap and target if the target value is nonzero
        <>
          <CostRow
            className="text-entities-gap"
            cost={report.gap}
            data-cy="cost-summary-gap"
            description="Additional changes needed to meet target"
            hasExactCost={hasExactCost}
            label={terms.titleCase(TermKey.GAP)}
          />
          <div className="col-span-2 h-px w-full bg-border-separator" />
          <CostRow
            className="text-entities-milestone"
            cost={report.target}
            data-cy="cost-summary-budget"
            description={`${terms.rawTerm(TermKey.TARGET)} value for this milestone`}
            hasExactCost={hasExactCost}
            label={terms.titleCase(TermKey.TARGET)}
          />
        </>
      ) : null}
    </Link>
  );
}
