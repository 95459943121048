import { MarkupModeSelect } from '../../../dragon-scales';
import { MarkupMode } from '../../../../generated/graphql';

export default function ProgramSettings() {
  // ToDo: Add query for program cost mode
  const markupMode = MarkupMode.ALLOCATED_MARKUPS;
  const availableMarkupModes: MarkupMode[] = [MarkupMode.ALLOCATED_MARKUPS];

  return (
    <div className="flex h-full flex-col gap-2" data-cy="settings">
      <MarkupModeSelect
        availableMarkupModes={availableMarkupModes}
        isDisabled
        label="Markups Display"
        onChange={() => {}} // Cannot currenty change this for programs
        value={markupMode}
      />
      {/* )} */}
    </div>
  );
}
