import useProjectPropsQuery from '../../../../hooks/useProjectPropsQuery';
import { useShouldDisplayCosts } from '../../../../utilities/permissions/useShouldDisplayCosts';

import HUD from '../HUD/HUD';
import ProjectTitle from '../Title/ProjectTitle';
import useHUDVisibility from './useHUDVisibility';

type Props = {
  projectID: UUID;
  /** @default true */
  isThumbnailVisible?: boolean;
};

export default function ProjectDetails(props: Props) {
  const { shouldDisplayCosts, setDisplayCostsToggle } = useShouldDisplayCosts(props.projectID);

  const { showHUD, onChangeHUDVisibility } = useHUDVisibility();

  const projectQueryResult = useProjectPropsQuery(props.projectID);
  const project =
    projectQueryResult.data.project ?? projectQueryResult.previousData?.project ?? undefined;

  if (!project) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2" data-cy="project-details">
      <div className="px-2">
        <ProjectTitle
          isShowingMilestoneInfo={showHUD}
          isThumbnailVisible={props.isThumbnailVisible}
          onShowMilestoneInfo={
            shouldDisplayCosts || setDisplayCostsToggle ? onChangeHUDVisibility : undefined
          }
          project={project}
        />
      </div>
      {showHUD && props.projectID && (
        <HUD milestoneID={project?.activeMilestone.id} projectID={props.projectID} />
      )}
    </div>
  );
}

ProjectDetails.defaultProps = {
  isThumbnailVisible: true,
};
