import { ReactNode } from 'react';

import { navEventTypes } from '../../../../analytics/analyticsEventProperties';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { isEnumValue } from '../../../../utilities/types';
import { Tab, TabList, Tabs } from '../../../scales';
import useSendNavAnalytics from '../../hooks/useSendNavAnalytics';
import { GearIcon } from '../../icons';

import ProgramSettings from './ProgramSettings';
import ProgramCostsSummary from './ProgramCostsSummary';
import { TabKeys } from './types';
import { HUDTabPanel } from './HUDTabPanel';

type Props = {
  programID: UUID;
};

export default function ProgramHUD(props: Props) {
  const sendNavAnalytics = useSendNavAnalytics();

  const [activeHUDTab, setActiveHUDTab] = useLocalStorage(
    'NAV_ACTIVE_PROGRAM_HUD_TAB',
    TabKeys.COSTS
  );
  const tabs = useTabs();
  if (!tabs.length) return null;

  return (
    <Tabs
      data-cy="hud"
      onChange={(key) => {
        if (typeof key === 'string' && isEnumValue(TabKeys, key)) {
          setActiveHUDTab(key);
          sendNavAnalytics(navEventTypes.HUD_TAB_CHANGED, { key });
        }
      }}
      // Make sure we always reset the current tab to one that actually exists. This handles
      // local-storage backcompat and any changes to permissions.
      value={tabs.find((t) => t.key === activeHUDTab) ? activeHUDTab : tabs[0].key}
    >
      <TabList data-cy="hud-tabs">{tabs.map((t) => t.element)}</TabList>
      <HUDTabPanel hasUnitToggleVisible={false} id={TabKeys.COSTS}>
        <ProgramCostsSummary programID={props.programID} />
      </HUDTabPanel>
      <HUDTabPanel hasUnitToggleVisible={false} id={TabKeys.SETTINGS}>
        <ProgramSettings />
      </HUDTabPanel>
    </Tabs>
  );
}

function useTabs() {
  const tabs: { key: string; element: ReactNode }[] = [
    {
      key: TabKeys.COSTS,
      element: (
        <Tab key={TabKeys.COSTS} id={TabKeys.COSTS} isCompact>
          Costs
        </Tab>
      ),
    },
    {
      key: 'settings',
      element: (
        <Tab key={TabKeys.SETTINGS} id={TabKeys.SETTINGS} isCompact isRightAligned>
          <GearIcon className="icon-md" />
        </Tab>
      ),
    },
  ];

  return tabs;
}
