import { ComponentProps } from 'react';

import { IconButton, ScrollContainer, Tooltip } from '../../scales';
import { Link } from '../types';

import CompanySection from './CompanySection';
import useLinks from './hooks/useLinks';
import NavItem from './NavItem';
import ProjectThumbnail from './ProjectThumbnail';
import SupportMenuButton from './SupportMenuButton';
import UserMenuButton from './UserMenuButton';
import ProgramThumbnail from '../../ProgramReporting/ProgramThumbnail';
import { LastViewedEntry } from './hooks/useLastViewedEntries';
import { useProgramQuery } from '../../ProgramReporting/hooks/useProgramQuery';
import { ExpandSidebarIcon } from '../icons';
import SearchButton from '../SearchButton';
import JoinLogoNavLink from './JoinLogoNavLink';
import NotificationsButton from './NotificationsButton';

type Props = {
  company: ComponentProps<typeof CompanySection>['company'];
  lastViewedEntries: LastViewedEntry[];
  onExpand: () => void;
  user: ComponentProps<typeof UserMenuButton>['user'];
};

export default function SidebarCollapsed(props: Props) {
  const lastViewedEntry = props.lastViewedEntries.length ? props.lastViewedEntries[0] : undefined;

  const { companyLinks, programLinks, projectLinks } = useLinks(lastViewedEntry);

  // notifications currently only work for projectIDs
  const lastViewedProjectID = props.lastViewedEntries.find((e) => e.type === 'project')?.id;

  return (
    <div className="flex h-full w-16 flex-col items-stretch gap-4">
      <header className="flex flex-col items-center">
        <div className="py-2">
          <JoinLogoNavLink size="md" />
        </div>
        <Tooltip content="Expand sidebar" offset={TOOLTIP_OFFSET} placement={TOOLTIP_PLACEMENT}>
          <IconButton
            aria-label="expand sidebar"
            icon={<ExpandSidebarIcon />}
            onClick={props.onExpand}
            type="secondary"
          />
        </Tooltip>
        <NotificationsButton
          projectID={lastViewedProjectID}
          tooltipOffset={TOOLTIP_OFFSET}
          tooltipPlacement={TOOLTIP_PLACEMENT}
        />
        <SearchButton tooltipOffset={TOOLTIP_OFFSET} tooltipPlacement={TOOLTIP_PLACEMENT} />
      </header>
      <Separator />
      <ScrollContainer direction="vertical">
        {lastViewedEntry && (
          <>
            {lastViewedEntry.type === 'project' ? (
              <ProjectSection links={projectLinks} projectID={lastViewedEntry.id} />
            ) : (
              <ProgramSection links={programLinks} programID={lastViewedEntry.id} />
            )}
            <div className="py-4">
              <Separator />
            </div>
          </>
        )}
        <CompanySection
          company={props.company}
          lastViewedEntries={props.lastViewedEntries}
          links={companyLinks}
        />
      </ScrollContainer>
      <footer className="mt-auto flex flex-col items-center justify-center gap-2 py-4">
        <UserMenuButton
          tooltipOffset={TOOLTIP_OFFSET}
          tooltipPlacement={TOOLTIP_PLACEMENT}
          user={props.user}
        />
        <SupportMenuButton
          position={{ left: 64 /* 64px is the width of the sidebar */ }}
          tooltipOffset={TOOLTIP_OFFSET}
          tooltipPlacement={TOOLTIP_PLACEMENT}
        />
      </footer>
    </div>
  );
}

const TOOLTIP_PLACEMENT = 'right';
const TOOLTIP_OFFSET = 16;

const Separator = () => <div className="h-px bg-border-separator" />;

type ProjectSectionProps = {
  projectID: UUID;
  links: Link[];
};
function ProjectSection(props: ProjectSectionProps) {
  return (
    <div className="flex flex-col items-stretch gap-2" data-cy="project">
      <ProjectThumbnail projectID={props.projectID} />
      <div data-cy="project--links">
        {props.links.map((link) => (
          <NavItem key={link.name} icon={link.icon} path={link.path} tooltip={link.name} />
        ))}
      </div>
    </div>
  );
}

type ProgramSectionProps = {
  programID: UUID;
  links: Link[];
};
function ProgramSection(props: ProgramSectionProps) {
  const program = useProgramQuery(props.programID)?.data?.program;
  if (!program) return null;

  return (
    <div className="flex flex-col items-stretch gap-2" data-cy="program">
      <div className="self-center">
        <ProgramThumbnail // ToDo: NS-1427 Add thumbnail component with NavPopover
          projectCount={program.projects.length}
          projectThumbnail={program.thumbnail}
          size="sm"
        />
      </div>
      <div data-cy="project--links">
        {props.links.map((link) => (
          <NavItem key={link.name} icon={link.icon} path={link.path} tooltip={link.name} />
        ))}
      </div>
    </div>
  );
}
