import { useReactiveVar } from '@apollo/client';

import { projectSettingsVar } from '../../../../api/apollo/reactiveVars';
import { EMPTY_COST } from '../../../../utilities/string';
import { Tooltip } from '../../../scales';
import { renderCostString } from '../../../CostReport/CostReportUtils';

type CostRowProps = {
  className: string;
  cost?: Cost;
  description: string;
  hasExactCost: boolean;
  label: string;
  'data-cy': string;
};
export default function CostRow(props: CostRowProps) {
  // "Subscribe" to changes in the ROUNDING_PRECISION so that the cost report re-renders.
  // This is necessary because the value is normally pulled in formatCost (via renderCostString).
  // Since they're plain functions and not hooks, changes to the value don't trigger a re-render.
  useReactiveVar(projectSettingsVar);

  const roundedCost = renderCostString({
    cost: props.cost,
    isExact: false,
    isWide: true,
  });

  const exactCost = renderCostString({
    cost: props.cost,
    isExact: true,
    isWide: true,
  });

  return (
    <>
      <Tooltip
        content={
          <div className="flex flex-col gap-0.5">
            <div className="type-label">{props.description}</div>
            <div className="type-table-number">{exactCost || EMPTY_COST}</div>
          </div>
        }
        isDisabled={!props.cost}
        offset={20}
        placement="right"
      >
        <div className={`${props.className} flex gap-1 type-body3`}>
          <div>{props.label}</div>
          <div className="ml-auto tabular-nums" data-cy={props['data-cy']}>
            {props.hasExactCost ? exactCost : roundedCost}
          </div>
        </div>
      </Tooltip>
    </>
  );
}
