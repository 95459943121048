import { Link } from 'react-router-dom';

import { generateSharedPath } from '../../../../utilities/routes/links';
import { useProgramCostsQuery } from '../../../ProgramReporting/hooks/useProgramCostsQuery';
import { RouteKeys } from '../../../../routes/paths';
import { getCostValue } from '../../../CostReport/CostReportUtils';
import { TerminologyDefaults } from '../../../ProjectDisplaySettings/constants';
import CostRow from './CostRow';

export default function ProgramCostsSummary(props: { programID: UUID }) {
  const cost = useProgramCostsQuery([], props.programID)?.data?.programCosts;

  const path = generateSharedPath(RouteKeys.PROGRAM_DASHBOARD, {
    programID: props.programID,
  });
  const hasNonzeroTarget = Boolean(getCostValue(cost?.budget));

  return (
    <Link
      className="flex flex-col gap-1 outline-none focus-visible:bg-selection-hover"
      data-cy="sidebar-cost-summary"
      to={path}
    >
      <CostRow
        className="text-type-primary"
        cost={{ value: cost?.estimate.toString() ?? '0' }}
        data-cy="cost-summary-estimate"
        description={TerminologyDefaults.ESTIMATE}
        hasExactCost={false}
        label={TerminologyDefaults.ESTIMATE}
      />
      <CostRow
        className="text-item-status-accepted"
        cost={{ value: cost?.accepted.toString() ?? '0' }}
        data-cy="cost-summary-accepted-changes"
        description="Value of all accepted items"
        hasExactCost={false}
        label="Accepted Changes"
      />
      <div className="col-span-2 h-px w-full bg-border-separator" />
      <CostRow
        className="text-type-primary"
        cost={{ value: cost?.runningTotal?.toString() ?? '0' }}
        data-cy="cost-summary-running-total"
        description={TerminologyDefaults.RUNNING_TOTAL}
        hasExactCost={false}
        label={TerminologyDefaults.RUNNING_TOTAL}
      />
      {hasNonzeroTarget ? ( // only show the gap and target if the target value is nonzero
        <>
          <CostRow
            className="text-entities-gap"
            cost={{ value: cost?.gap?.toString() ?? '0' }}
            data-cy="cost-summary-gap"
            description={TerminologyDefaults.GAP}
            hasExactCost={false}
            label={TerminologyDefaults.GAP}
          />
          <div className="col-span-2 h-px w-full bg-border-separator" />
          <CostRow
            className="text-entities-milestone"
            cost={{ value: cost?.budget?.toString() ?? '0' }}
            data-cy="cost-summary-budget"
            description={TerminologyDefaults.TARGET}
            hasExactCost={false}
            label={TerminologyDefaults.TARGET}
          />
        </>
      ) : null}
    </Link>
  );
}
