import { useCallback, useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { isUUID } from 'validator';
import { useParams } from 'react-router-dom';

import { useProjectID } from '../../../../utilities/routes/params';
import { useCurrentUser } from '../../../contexts/current-user';

const LOCAL_STORAGE_KEY = 'NAV_LATEST_PROJECTS';
const LOCAL_STORAGE_ENTRIES_KEY = 'NAV_LATEST_ENTRIES';
const IGNORED_URLS = [
  // When selecting the Insights project details page
  '/insights',
];

export type LastViewedEntry = {
  id: UUID;
  type: 'project' | 'program';
};

export default function useLastViewedEntries() {
  const userID = useCurrentUser().id;

  const [lastViewedProjectIDs] = useMRUCache<UUID>(`${LOCAL_STORAGE_KEY}:${userID}`, 5, []);

  // Start storing the recently-used projects/programs as a struct to determine types
  // After a release or two we'll only use this value and remove the previous one.
  const [lastViewedEntries, setLastViewedEntries] = useMRULastViewedCache(
    `${LOCAL_STORAGE_ENTRIES_KEY}:${userID}`,
    5,
    lastViewedProjectIDs.map((id) => ({ id, type: 'project' }))
  );

  // Whenever we've nav'd to a new project, set it as most-recently-used.
  const projectID = useProjectID();
  const { programID } = useParams();

  useEffect(() => {
    const shouldIgnore = IGNORED_URLS.some((path) => window.location.pathname.startsWith(path));
    if (projectID && isUUID(projectID) && !shouldIgnore) {
      setLastViewedEntries({ id: projectID, type: 'project' });
    } else if (programID && isUUID(programID) && !shouldIgnore) {
      setLastViewedEntries({ id: programID, type: 'program' });
    }
  }, [programID, projectID, setLastViewedEntries]);

  return lastViewedEntries;
}

function useMRUCache<T>(key: string, size: number, initialValue: T[]) {
  /**
   * We explitly use a two-level cache here rather than just relying on
   * useLocalStorage. The reason behind this is that we want an updater-form of
   * the setter function so that we can memoize well and uLS has a bug with
   * that form.
   */
  const [localStorageCache, setLocalStorageCache] = useLocalStorage<T[]>(key, initialValue);
  const [cache, setCache] = useState(localStorageCache);

  const onTouch = useCallback(
    (entry: T) => {
      setCache((prevState = []) => {
        const newValue = [entry, ...prevState.filter((v) => v !== entry)].slice(0, size);

        setLocalStorageCache(newValue);
        return newValue;
      });
    },
    [setLocalStorageCache, size]
  );

  return [cache ?? [], onTouch] as const;
}

function useMRULastViewedCache(key: string, size: number, initialValue: LastViewedEntry[]) {
  /**
   * We explitly use a two-level cache here rather than just relying on
   * useLocalStorage. The reason behind this is that we want an updater-form of
   * the setter function so that we can memoize well and uLS has a bug with
   * that form.
   */
  const [localStorageCache, setLocalStorageCache] = useLocalStorage<LastViewedEntry[]>(
    key,
    initialValue
  );
  const [cache, setCache] = useState(localStorageCache);

  const onTouch = useCallback(
    (entry: LastViewedEntry) => {
      setCache((prevState = []) => {
        const newValue = [entry, ...prevState.filter((v) => v.id !== entry.id)].slice(0, size);
        setLocalStorageCache(newValue);
        return newValue;
      });
    },
    [setLocalStorageCache, size]
  );

  return [cache ?? [], onTouch] as const;
}
