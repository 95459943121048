import { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';
import { IconButton, Typography } from '@material-ui/core';
import { Clear, Fullscreen, Print } from '@material-ui/icons';

import {
  ItemShareKey,
  itemSidebarMenuClose,
  itemSidebarMenuOpen,
  itemSidebarMenuOpenFullscreen,
  itemSidebarMenuOpenPrint,
} from '../../../analytics/analyticsEventProperties';
import { itemSidebarOpenVar, itemSidebarScenariosOpenVar } from '../../../api/apollo/reactiveVars';
import { JoinProjectRoutes } from '../../../api/gqlEnums';
import { OPTION } from '../../../constants';
import { PermissionResource, ResourceType } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { RouteKeys } from '../../../routes/paths';
import { ITEMSIDEBAR_TITLE } from '../../../tagConstants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../../utilities/routes/links';
import { capitalizeString } from '../../../utilities/string';
import { hasSharedResourceAccess } from '../../../utilities/utilities';
import { useCurrentCollaborator } from '../../contexts/current-collaborator';
import { useCurrentUser } from '../../contexts/current-user';
import { ItemTypes } from '../../CostReport/CostReportList/CostReportListRow/CostReportListRowUtils';
import { GroupsIcon } from '../../Icons/GroupsIcon';
import ShareGroupAdd from '../../Icons/ShareGroupAdd';
import ItemPublishDialog from '../../Items/ItemPublishModal/ItemPublishDialog';
import ItemsListItemPrivateBadge from '../../Items/ItemsListItem/ItemsListItemPrivateBadge/ItemsListItemPrivateBadge';
import { formatItemDescriptor } from '../../Items/ItemsQuickLink/ItemsQuickLink';
import {
  isNotAChosenOption,
  isOptionOfPrivateItem,
  isPrivateVisibility,
  isScenarioVisibility,
} from '../../Items/ItemsUtils';
import { hasPrivateItemEditPermission } from '../../Items/ItemVisibilityToggle/ItemVisibilityToggleUtils';
import ItemsIcons from '../../ItemsList/ItemsIcons/ItemsIcons';
import { MEDIUM } from '../../ItemsList/ItemsIcons/ItemsIconsMap';
import { useGetSharedResource } from '../../ItemsList/ItemsSharing/hooks/useGetShareResourceHook';
import ShareDraftItemDialog from '../../ItemsList/ItemsSharing/ShareDraftItemDialog/ShareDraftItemDialog';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { Chip } from '../../scales';
import IconMenu from '../../Select/SelectMenu/IconMenu';
import { MenuOption } from '../../Select/SelectMenu/SelectOption';
import { SelectVariants } from '../../Select/SelectMenu/SelectStyles';
import useMemoWrapper from '../../useMemoWrapper';

import ItemSidebarHeaderStyles from './ItemSidebarHeaderStyles';

// returns formatted string for item sidebar navigation tooltips
export const itemNavigationTooltipText = (
  direction: string,
  item: ItemTypes | null,
  ellipses: boolean,
  showNumberSign: boolean,
  isDisabled: boolean
) => {
  if (item && item.id && !isDisabled) {
    const itemTitle = formatItemDescriptor(item, ellipses, showNumberSign);
    const navDirection = capitalizeString(direction);
    return `${navDirection} item: ${itemTitle}`;
  }
  return `No ${direction} item`;
};

export const itemNavigationLink = (projectId: UUID, itemId?: UUID) =>
  generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
    projectId,
    itemId,
  });

// Plan to leave this doc for queries, permissions and all things calculations
type ItemSidebarHeaderProps = {
  classes: Classes<typeof ItemSidebarHeaderStyles>;
  closeSidebar: () => void;
  isProjectItemSidebar?: boolean;
  item?: ItemLike;
  items?: ItemTypes[] | null;
  projectId: UUID;
  onItemMutated?: () => void;
  sharedUsers: Pick<User, 'id' | 'name'>[];
};

const ItemSidebarHeader: FC<ItemSidebarHeaderProps> = ({
  classes,
  closeSidebar,
  isProjectItemSidebar,
  item,
  items = null,
  projectId,
  onItemMutated,
  sharedUsers,
}) => {
  const navigate = useNavigate();
  const sendAnalytics = useSendAnalytics();

  // LOCAL CONSTANTS
  const [showPublishItemDialog, setShowPublishItemDialog] = useState(false);
  const [showShareItemDialog, setShowShareItemDialog] = useState(false);

  const ellipses = true;
  const showNumberSign = true;
  const itemIDs: string[] | null = items
    ? items
        .map((item: ItemTypes) => {
          return item?.id;
        })
        .filter((i): i is string => !!i)
    : null;

  // item could be null if the item query hasn't yet completed
  const {
    id: itemID,
    status = undefined,
    availableStates = [],
    itemType = undefined,
    visibility,
  } = item || {};
  const { parent, parentVisibility } = (item as Option) || {};

  const currentUserID = useCurrentUser().id;
  const collaborator = useCurrentCollaborator();

  const { data: sharedResourceData } = useGetSharedResource(itemID, ResourceType.ITEM);
  const currentUserHasSharedResourceAccess = useMemoWrapper(
    hasSharedResourceAccess,
    sharedResourceData,
    currentUserID
  );
  // Permissions
  const { canEdit } = usePermissions();
  const isItemCreator = collaborator?.user.id === item?.createdBy?.id;
  const canShareDraft = canEdit(PermissionResource.SHARE_ITEMS_SCENARIOS) || isItemCreator;
  const canPublishDraft = canEdit(PermissionResource.PUBLISH_ITEMS_SCENARIOS) || isItemCreator;

  const createdByID = item?.createdBy?.id;
  const canViewEditPrivateItem =
    !!createdByID &&
    !!currentUserID &&
    (hasPrivateItemEditPermission(createdByID, currentUserID) ||
      currentUserHasSharedResourceAccess);

  const hasPrivateParent = isOptionOfPrivateItem(!!parent, parentVisibility ?? undefined);
  const isPrivate = isPrivateVisibility(visibility);

  // Visibility
  const isScenario = isScenarioVisibility(visibility);
  const { color } = useReactiveVar(itemSidebarScenariosOpenVar);

  const showPublishOption = canViewEditPrivateItem && !hasPrivateParent && isPrivate;

  const menuOptions: MenuOption[] = [
    {
      callback: () => {
        if (itemID) {
          sendAnalytics(itemSidebarMenuOpenFullscreen(itemID));
          itemSidebarOpenVar(null);
          navigate({
            pathname: itemNavigationLink(projectId, itemID ?? undefined),
          });
        }
      },
      name: 'Fullscreen item',
      icon: <Fullscreen />,
    },
    {
      callback: () => {
        if (itemID) {
          sendAnalytics(itemSidebarMenuOpenPrint(itemID));
          window.open(
            generateSharedPath(JoinProjectRoutes.PRINT_ITEM_DETAILS, {
              projectId,
              itemId: itemID,
            }),
            '_blank'
          );
        }
      },
      name: 'Print item',
      icon: <Print />,
    },
  ];

  const publishItemOption: MenuOption = {
    callback: () => {
      setShowPublishItemDialog(true);
    },
    disabled: !canPublishDraft,
    name: 'Publish Item',
    icon: <GroupsIcon />,
  };

  const shareDraftItemOption: MenuOption = {
    callback: () => {
      setShowShareItemDialog(true);
    },
    disabled: !canShareDraft,
    name: 'Share Draft',
    icon: <ShareGroupAdd />,
  };

  if (showPublishOption) {
    if (canPublishDraft) menuOptions.unshift(publishItemOption);
    if (canShareDraft) menuOptions.unshift(shareDraftItemOption);
  }

  useEffect(() => {
    if (itemIDs && itemID && itemIDs.indexOf(itemID) === -1) itemSidebarOpenVar(null);
  }, [itemIDs, itemID]);

  const itemTitle = item ? formatItemDescriptor(item, ellipses, showNumberSign) : '';
  const notChosen = isNotAChosenOption(availableStates, itemType === OPTION, status);

  return (
    <div className={classes.summaryHeader}>
      <div
        className={`flex items-center gap-1 ${
          isProjectItemSidebar ? classes.itemHeaderProject : classes.itemHeaderForecasting
        }`}
      >
        {isScenario ? (
          <div className="px-2">
            <Chip backgroundColor={color} text="New" />
          </div>
        ) : (
          <div className={classes.optionsContainer}>
            <ItemsIcons notChosen={notChosen} status={status} variant={MEDIUM} />
          </div>
        )}
        {isProjectItemSidebar && !isScenario ? (
          <div className={classes.itemNameContainer}>
            <Link
              className={classes.itemNameLink}
              to={itemNavigationLink(projectId, itemID ?? undefined)}
            >
              <div className="flex gap-2">
                {isPrivateVisibility(visibility) && (
                  <ItemsListItemPrivateBadge
                    analyticsKey={ItemShareKey.SIDEBAR}
                    sharedPrivateItemUsers={sharedUsers}
                  />
                )}
                <NormalTooltip placement="bottom" title={itemTitle}>
                  <Typography className={`${classes.itemName} ${classes.underline}`}>
                    {itemTitle}
                  </Typography>
                </NormalTooltip>
              </div>
            </Link>
          </div>
        ) : (
          <div className={classes.itemNameContainer}>
            <NormalTooltip placement="bottom" title={itemTitle}>
              <Typography className={classes.itemName} data-cy={ITEMSIDEBAR_TITLE}>
                {itemTitle}
              </Typography>
            </NormalTooltip>
          </div>
        )}
      </div>

      <div className={classes.displayFlex}>
        {isProjectItemSidebar && !isScenario && (
          <div className={classes.headerButtonContainer}>
            <IconMenu
              id="item-details-menu-button"
              onClick={() => itemID && sendAnalytics(itemSidebarMenuOpen(itemID))}
              options={menuOptions}
              variant={SelectVariants.HEADER}
            />
          </div>
        )}
        <div className={classes.headerButtonContainer}>
          <IconButton
            classes={{ root: classes.closeButton }}
            data-cy="close-item-sidebar"
            disableRipple
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
            onClick={(event: any) => {
              event.stopPropagation();
              if (itemID) {
                sendAnalytics(itemSidebarMenuClose(itemID));
                closeSidebar();
              }
            }}
          >
            <Clear />
          </IconButton>
        </div>
      </div>
      {itemID && createdByID && (
        <ItemPublishDialog
          isOpen={showPublishItemDialog}
          itemCreatedByID={createdByID}
          itemID={itemID}
          onItemMutated={onItemMutated}
          projectID={projectId}
          setIsOpen={setShowPublishItemDialog}
        />
      )}
      {itemID && showShareItemDialog && collaborator?.id && (
        <ShareDraftItemDialog
          collaboratorID={collaborator?.id}
          itemIDs={[itemID]}
          onClose={() => setShowShareItemDialog(false)}
          projectID={projectId}
        />
      )}
    </div>
  );
};

export default withStyles(ItemSidebarHeaderStyles)(ItemSidebarHeader);
